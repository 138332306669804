/* Risk Registry Management, user, Edit, incident */
.MuiDialog-paper:has(.increase-modal_width) {
    width: 70%;
}
.MuiDialog-paper:has(.inherit_max_width) {
    max-width: 600px;
}
.fields_column_spacing .MuiGrid-spacing-xs-1 > .MuiGrid-item {
    padding: 6px 4px;
}

.MuiFormControl-root.notNewColumn .MuiInputBase-input {
    color: #000 !important;
}

.MuiFormControl-root.notNewColumn svg {
    display: none;
}

/* .MuiFormControl-root:has(.notNewColumn) .MuiInputBase-input {
    color: #000 !important;
}

.MuiFormControl-root:has(.notNewColumn) svg {
    display: none;
} */



/* item details and category tab  */
.card_header_tab_style > div {
    margin: 0 !important;
}
.card_header_tab_style {
    border-bottom: 1px solid #dee2e6;
}
.card_header_tab_style > div a,
.card_header_tab_style > div span {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    font-size: 18px;
    display: block;
    padding: 8px 14px;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    cursor: pointer;
    font-weight: 600;
    color: black;
}
.card_header_tab_style > .act a,
.card_header_tab_style > .act span {
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    color: #0000ff;
    cursor: default;
}
.card_header_tab_style > div a:hover,
.card_header_tab_style > div span:hover {
    color: #0000ff;
}

/* modal header title for Recommendation count css */
.select_recommendation > div > div + div {
    position: static;
}

/* modal header title for Recommendation count css */