.ql-container {
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 1px;
}

/* Styling the Quill editor content area */
.ql-editor {
    min-height: 85vh;
    background: #e5e5e5;
    margin: -8px;
}

/* Styling the toolbar */
.ql-toolbar {
    border: 1px solid #ccc;
    border-radius: 1px;
    background-color: #f7f7f7;
}

/* Styling the toolbar buttons */
.ql-toolbar .ql-formats {
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: 14px;
}

/* Styling the toolbar buttons on hover */
.ql-toolbar .ql-formats:hover {
    background-color: #eee;
    cursor: pointer;
}



.ql-container.ql-snow {
    height: 800px !important;
    overflow: auto;
    border: none;
}

/* .ql-container.ql-snow::-webkit-scrollbar {
    width: 12px;
    background-color: #b11a1a;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
} */

.ql-container.ql-snow::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.ql-container.ql-snow::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.ql-container.ql-snow::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #0057ff;
}

.ql-editor p {
    font-size: 16px;
}